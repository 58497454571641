<template>
  <div class="page">
    <div class="c-emailsent c-emailsent_h-800">
      <div class="c-emailsent__body c-emailsent__body_top-208">
        <img src = "@/assets/images/email-logo.svg" alt="email-check">
        <h2 class="c-emailsent__title"><span class="c-emailsent__title_black">Verification Email</span> Sent</h2>
        <p class="c-emailsent__content c-emailsent__content-verification">We have sent you a verification email that provides further instruction. If you do not see the email in your inbox, please check your spam folder.</p>
        <a class="c-emailsent__link" @click='routerRedirect("/signin")'>Return to Home >></a>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'VerificationEmailSent',
  components: {
  },
  data() {
    return {
    }
  },
}
</script>
